<template>
  <div class="Home">
    <div class="top">
      <div class="top_div">
        <div v-if="bigNews.id" class="big_news">
          <a :href="bigNews.link">{{bigNews.title}}</a>
        </div>
        <div class="news">
          <div class="left">
            <div v-if="banner && banner.length>0" class="banner">
              <el-carousel
                height="100%"
                ref="banner"
                indicator-position="none"
                arrow="never"
                @change="changeBanner"
                :initial-index="bannerIndex"
              >
                <el-carousel-item v-for="(item, index) in banner" :key="index">
                  <div
                    class="banner_item"
                    :style="'background-image: url('+item.coverUrl+');'"
                    @click="goBanner(item)"
                  ></div>
                </el-carousel-item>
              </el-carousel>
              <div class="indicator">
                <div
                  v-for="(item, index) in banner"
                  :key="index"
                  :class="'indicator_item'+(bannerIndex==index?' current':'')"
                  @click="goBannerIndex(index)"
                >{{index+1}}</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title">消防要闻</div>
            <div class="right_div">
              <a
                v-for="(item, index) in topNews"
                :key="index"
                :href="'/news/'+item.id"
                target="_blank"
              >{{item.title}}</a>
            </div>
          </div>
        </div>
        <div v-if="picNews.length>0" class="pics">
          <router-link
            v-for="(item, index) in picNews"
            :key="index"
            :to="'/topic/'+item.id"
            :style="'background-image: url('+item.coverUrl+');'"
          ></router-link>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="line">
        <div class="left">
          <div class="left_title">
            <div class="title">通知公告</div>
            <div class="more">
              <router-link :to="'/column/'+columns[4]">更多</router-link>
            </div>
          </div>
          <div class="block news2">
            <div class="news_list">
              <a
                v-for="(item, index) in news5"
                :key="index"
                :href="'/news/'+item.id"
                target="_blank"
              >{{item.title}}</a>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">办事服务</div>
          <div class="block block1">
            <a
              href="https://search.sh.gov.cn/search?text=%E6%B6%88%E9%98%B2&siteId=www.shanghai.gov.cn"
            >
              <div>公众聚集场所使用或开业</div>
              <span>点击访问</span>
            </a>
            <a href="https://xfjd.119.gov.cn:1890/xygk/?cityCode=310000#/ssj/index">
              <div>双随机公开</div>
              <span>点击访问</span>
            </a>
            <a
              href="https://search.sh.gov.cn/search?text=%E6%B6%88%E9%98%B2%E6%B3%A8%E5%86%8C%E5%B7%A5%E7%A8%8B%E5%B8%88&siteId=www.shanghai.gov.cn"
            >
              <div>注册消防工程师</div>
              <span>点击访问</span>
            </a>
            <a href="http://js.shxf-119.com/login">
              <div>上海市消防技术服务管理系统</div>
              <span>点击访问</span>
            </a>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="left">
          <div class="left_title">
            <div class="nav">
              <div :class="'nav_item'+(nav==1?' current':'')" @click="nav=1">政策解读</div>
              <div :class="'nav_item'+(nav==2?' current':'')" @click="nav=2">执法公开</div>
              <div :class="'nav_item'+(nav==3?' current':'')" @click="nav=3">法律法规</div>
              <div :class="'nav_item'+(nav==4?' current':'')" @click="nav=4">解读回应</div>
            </div>
            <div v-if="nav==1" class="more">
              <router-link :to="'/column/'+columns[0]">更多</router-link>
            </div>
            <div v-else-if="nav==2" class="more">
              <router-link :to="'/column/'+columns[1]">更多</router-link>
            </div>
            <div v-else-if="nav==3" class="more">
              <router-link :to="'/column/'+columns[2]">更多</router-link>
            </div>
            <div v-else-if="nav==4" class="more">
              <router-link :to="'/column/'+columns[3]">更多</router-link>
            </div>
          </div>
          <div v-if="nav==1" class="block news1">
            <div class="news_list">
              <a
                v-for="(item, index) in news1"
                :key="index"
                :href="'/news/'+item.id"
                target="_blank"
              >{{item.title}}</a>
            </div>
          </div>
          <div v-else-if="nav==2" class="block news1">
            <div class="news_list">
              <a
                v-for="(item, index) in news2"
                :key="index"
                :href="'/news/'+item.id"
                target="_blank"
              >{{item.title}}</a>
            </div>
          </div>
          <div v-else-if="nav==3" class="block news1">
            <div class="news_list">
              <a
                v-for="(item, index) in news3"
                :key="index"
                :href="'/news/'+item.id"
                target="_blank"
              >{{item.title}}</a>
            </div>
          </div>
          <div v-else-if="nav==4" class="block news1">
            <div class="news_list">
              <a
                v-for="(item, index) in news4"
                :key="index"
                :href="'/news/'+item.id"
                target="_blank"
              >{{item.title}}</a>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">互动交流</div>
          <div class="block block2">
            <div class="link">
              <a href="https://zwdtuser.sh.gov.cn/uc/login/login.jsp" target="_blank">公众留言</a>
              <a
                href="https://xfb.sh.gov.cn/xinfang/xfb/ywtb/loginNotice?type=ts&url=%2Fxinfang%2Fyjzj%2Fletter%2Fmailboxts"
                target="_blank"
              >投诉举报</a>
            </div>
            <a class="tel" href="http://www.sh12345.gov.cn/" target="_blank">市民热线：12345</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getRecommendList, getTopicList } from "@/axios/home";
import { getColumnNews } from "@/axios/column";

export default {
  name: "Home",
  data() {
    return {
      bigNews: {},
      banner: [],
      bannerIndex: 0,
      topNews: [],
      picNews: [],
      nav: 1,
      columns: [
        "policy-interpretation",
        "information-disclosure",
        "laws",
        "message",
        "notice-announcement"
      ],
      news1: [],
      news2: [],
      news3: [],
      news4: [],
      news5: []
    };
  },
  mounted() {
    this.loadBigNews();
    this.loadBanner();
    this.loadTopNews();
    this.loadPicNews();
    this.loadNews1();
    this.loadNews2();
    this.loadNews3();
    this.loadNews4();
    this.loadNews5();
  },
  methods: {
    loadBigNews() {
      let query = "pageNum=1&pageSize=1&recommendLabel=home-top";
      getRecommendList(query).then(res => {
        if (res.status == 1) {
          if (res.data && res.data.list && res.data.list.length > 0)
            this.bigNews = res.data.list[0];
        }
      });
    },
    loadBanner() {
      let query = "pageNum=1&pageSize=4&recommendLabel=home-banner";
      getRecommendList(query).then(res => {
        if (res.status == 1) {
          this.banner = res.data.list;
        }
      });
    },
    goBanner(item) {
      if (item.link)
        this.$router.push({
          path: item.link
        });
    },
    changeBanner(i) {
      this.bannerIndex = i;
    },
    goBannerIndex(i) {
      this.$refs.banner.setActiveItem(i);
    },
    loadTopNews() {
      let query = "pageNum=1&pageSize=9&columnLabel=fire-news";
      getColumnNews(query).then(res => {
        if (res.status == 1) {
          this.topNews = res.data.list;
        }
      });
    },
    loadPicNews() {
      let query = "pageNum=1&pageSize=5";
      getTopicList(query).then(res => {
        if (res.status == 1) {
          this.picNews = res.data.list;
        }
      });
    },
    loadNews1() {
      let query = "pageNum=1&pageSize=5&columnLabel=" + this.columns[0];
      getColumnNews(query).then(res => {
        if (res.status == 1) {
          this.news1 = res.data.list;
        }
      });
    },
    loadNews2() {
      let query = "pageNum=1&pageSize=5&columnLabel=" + this.columns[1];
      getColumnNews(query).then(res => {
        if (res.status == 1) {
          this.news2 = res.data.list;
        }
      });
    },
    loadNews3() {
      let query = "pageNum=1&pageSize=5&columnLabel=" + this.columns[2];
      getColumnNews(query).then(res => {
        if (res.status == 1) {
          this.news3 = res.data.list;
        }
      });
    },
    loadNews4() {
      let query = "pageNum=1&pageSize=5&columnLabel=" + this.columns[3];
      getColumnNews(query).then(res => {
        if (res.status == 1) {
          this.news4 = res.data.list;
        }
      });
    },
    loadNews5() {
      let query = "pageNum=1&pageSize=5&columnLabel=" + this.columns[4];
      getColumnNews(query).then(res => {
        if (res.status == 1) {
          this.news5 = res.data.list;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.Home {
  .top {
    background: #fff;
    .top_div {
      padding-top: 24px;
      padding-bottom: 32px;
      @include body_block;
      @media (max-width: $mob-screen) {
        padding-top: 16px;
        padding-bottom: 16px;
      }
      .big_news {
        text-align: center;
        margin-bottom: 24px;
        @media (max-width: $mob-screen) {
          margin-bottom: 16px;
        }
        a {
          line-height: 40px;
          font-size: 28px;
          color: #000;
          font-weight: 600;
          @media (max-width: $mob-screen) {
            line-height: 20px;
            font-size: 14px;
            display: block;
            @include single_line_ellipsis;
          }
        }
      }
      .news {
        @include flex_between_center;
        @media (max-width: $mob-screen) {
          display: block;
        }
        .left {
          width: 55.29%;
          @media (max-width: $mob-screen) {
            width: 100%;
          }
          .banner {
            border-radius: 6px;
            overflow: hidden;
            height: 398px;
            position: relative;
            @media (max-width: 860px) {
              height: 300px;
            }
            @media (max-width: $mob-screen) {
              height: 238px;
            }
            .el-carousel {
              height: 100%;
              /deep/ .banner_item {
                height: 100%;
                cursor: pointer;
                @include pic_bg_cover;
              }
            }
            .indicator {
              position: absolute;
              z-index: 90;
            }
            .indicator {
              left: 50%;
              transform: translateX(-50%);
              bottom: 22px;
              pointer-events: none;
              @include flex_center_center;
              @media (max-width: $mob-screen) {
                bottom: 9px;
              }
              .indicator_item {
                width: 32px;
                height: 32px;
                margin: 0 5px;
                line-height: 22px;
                font-size: 16px;
                background: rgba(0, 0, 0, 0.4);
                cursor: pointer;
                color: #fff;
                pointer-events: auto;
                @include flex_center_center;
                @media (max-width: $mob-screen) {
                  width: 3px;
                  height: 3px;
                  margin: 0 2px;
                  text-indent: -9000px;
                  border-radius: 2px;
                }
                &.current {
                  background: $primary_blue;
                  @media (max-width: $mob-screen) {
                    width: 11px;
                    background: #fff;
                  }
                }
              }
            }
          }
        }
        .right {
          width: 43%;
          height: 398px;
          background: #f5f6f9;
          padding: 16px 28px 14px 28px;
          border-radius: 6px;
          @include flex_start_start;
          flex-direction: column;
          @media (max-width: 860px) {
            height: 300px;
          }
          @media (max-width: $mob-screen) {
            display: block;
            margin-top: 16px;
            width: 100%;
            height: auto;
            padding: 8px;
          }
          .title {
            flex-shrink: 0;
            width: 100%;
            position: relative;
            padding-left: 14px;
            padding-bottom: 12px;
            font-size: 24px;
            font-weight: 500;
            color: #000;
            line-height: 33px;
            @media (max-width: $mob-screen) {
              padding-left: 8px;
              font-size: 14px;
              line-height: 20px;
            }
            &:before {
              display: block;
              content: "";
              width: 6px;
              height: 23px;
              background: #b70000;
              position: absolute;
              left: 0;
              top: 5px;
              @media (max-width: $mob-screen) {
                width: 4px;
                height: 10px;
                top: 5px;
              }
            }
            &:after {
              display: block;
              content: "";
              height: 1px;
              background: linear-gradient(
                270deg,
                rgba(183, 0, 0, 0) 0%,
                #b70000 100%
              );
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
            }
          }
          .right_div {
            flex-grow: 1;
            width: 100%;
            margin-top: 14px;
            overflow-y: auto;
            @media (max-width: $mob-screen) {
              margin-top: 8px;
              height: auto;
            }
            a {
              display: block;
              line-height: 28px;
              font-size: 20px;
              color: #242424;
              position: relative;
              padding-left: 20px;
              margin-top: 12px;
              @media (max-width: $mob-screen) {
                line-height: 16px;
                font-size: 12px;
                padding-left: 12px;
                margin-top: 8px;
              }
              &:first-child {
                margin-top: 0;
              }
              &:before {
                display: block;
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: $primary_red;
                position: absolute;
                top: 11px;
                left: 0;
                @media (max-width: $mob-screen) {
                  top: 6px;
                }
              }
            }
          }
        }
      }
      .pics {
        margin-top: 4px;
        @include flex_start_start;
        flex-wrap: wrap;
        @media (max-width: $mob-screen) {
          margin-top: 0;
          display: block;
        }
        a {
          display: block;
          width: calc(20% - 16px);
          height: 120px;
          margin-left: 20px;
          margin-top: 20px;
          border-radius: 8px;
          @include pic_bg_cover;
          @media (max-width: 860px) {
            height: 80px;
          }
          @media (max-width: $mob-screen) {
            margin-top: 16px;
            margin-left: 0;
            width: 100%;
          }
          &:nth-child(5n + 1) {
            margin-left: 0;
          }
        }
      }
    }
  }
  .content {
    padding-bottom: 40px;
    @media (max-width: $mob-screen) {
      padding-bottom: 16px;
    }
    @include body_block;
    .line {
      padding-top: 32px;
      @include flex_between_stretch;
      @media (max-width: $mob-screen) {
        display: block;
        padding-top: 16px;
      }
      .left,
      .right {
        @include flex_start_start;
        flex-direction: column;
        .title {
          position: relative;
          line-height: 33px;
          font-size: 24px;
          font-weight: 500;
          color: #242424;
          padding-left: 14px;
          padding-bottom: 12px;
          @media (max-width: 850px) {
            padding-left: 8px;
            line-height: 26px;
            font-size: 16px;
          }
          @media (max-width: $mob-screen) {
            line-height: 20px;
            font-size: 14px;
            padding-bottom: 10px;
          }
          &:before {
            display: block;
            content: "";
            width: 6px;
            height: 23px;
            background: #232323;
            position: absolute;
            left: 0;
            top: 5px;
            @media (max-width: 850px) {
              width: 4px;
              height: 16px;
            }
            @media (max-width: $mob-screen) {
              height: 10px;
            }
          }
        }
        .block {
          width: 100%;
          background: #fff;
          flex-grow: 1;
          border-radius: 6px;
        }
      }
      .left {
        width: 55.29%;
        @media (max-width: $mob-screen) {
          width: 100%;
        }
        .left_title {
          width: 100%;
          @include flex_between_start;
          .more {
            flex-shrink: 0;
            a {
              display: block;
              margin-left: 20px;
              margin-top: 3px;
              line-height: 26px;
              font-size: 16px;
              color: #888;
              padding-right: 10px;
              background: url(../assets/images/home_more.png) no-repeat right
                center;
              background-size: 8px auto;
              @media (max-width: 980px) {
                margin-left: 6px;
              }
              @media (max-width: 850px) {
                margin-top: 0;
                font-size: 12px;
              }
              @media (max-width: $mob-screen) {
                line-height: 20px;
              }
            }
          }
        }
        .nav {
          line-height: 33px;
          font-size: 24px;
          padding-bottom: 12px;
          @include flex_start_start;
          @media (max-width: 850px) {
            line-height: 26px;
            font-size: 16px;
          }
          @media (max-width: $mob-screen) {
            line-height: 20px;
            font-size: 14px;
            padding-bottom: 10px;
          }
          .nav_item {
            font-weight: 400;
            color: #000;
            cursor: pointer;
            margin-left: 40px;
            @media (max-width: 1110px) {
              margin-left: 16px;
            }
            @media (max-width: 980px) {
              margin-left: 6px;
            }
            @media (max-width: 900px) {
              margin-left: 2px;
            }
            @media (max-width: 850px) {
              margin-left: 6px;
            }
            &:first-child {
              margin-left: 0;
            }
            &.current {
              position: relative;
              font-weight: 500;
              color: $primary_red;
              padding-left: 14px;
              @media (max-width: 850px) {
                padding-left: 8px;
              }
              &:before {
                display: block;
                content: "";
                width: 6px;
                height: 23px;
                background: $primary_red;
                position: absolute;
                left: 0;
                top: 5px;
                @media (max-width: 850px) {
                  width: 4px;
                  height: 16px;
                }
                @media (max-width: $mob-screen) {
                  height: 10px;
                }
              }
            }
          }
        }
        .block {
          padding: 16px 24px 18px 20px;
          line-height: 25px;
          font-size: 18px;
          @media (max-width: $mob-screen) {
            padding: 16px;
            line-height: 18px;
            font-size: 12px;
          }
          .news_list {
            a {
              display: block;
              color: #242424;
              margin-top: 12px;
              padding-left: 26px;
              position: relative;
              @media (max-width: $mob-screen) {
                margin-top: 8px;
                padding-left: 12px;
              }
              &:before {
                display: block;
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: $primary_red;
                position: absolute;
                top: 10px;
                left: 6px;
                @media (max-width: $mob-screen) {
                  top: 7px;
                  left: 0;
                }
              }
              &:first-child {
                margin-top: 0;
              }
            }
          }
          &.news2 {
            .news_list {
              a {
                &:before {
                  background: $primary_blue;
                }
              }
            }
          }
        }
      }
      .right {
        width: 43%;
        @media (max-width: $mob-screen) {
          width: 100%;
          padding-top: 16px;
        }
        .block {
          &.block1 {
            padding: 8px;
            @include flex_start_stretch;
            flex-wrap: wrap;
            @media (max-width: 810px) {
              display: block;
              padding: 0 20px;
            }
            @media (max-width: $mob-screen) {
              padding: 0 16px;
            }
            a {
              display: block;
              width: calc(50% - 16px);
              background: #f5f6f9;
              border-radius: 6px;
              padding: 23px 0 21px 16px;
              margin: 8px;
              @media (max-width: 810px) {
                width: 100%;
                margin: 20px 0;
              }
              @media (max-width: $mob-screen) {
                border-radius: 4px;
                padding: 8px 10px;
                margin: 16px 0;
              }
              div {
                line-height: 25px;
                font-size: 18px;
                font-weight: 500;
                color: $primary_blue;
                @include flex_start_start;
                @media (max-width: $mob-screen) {
                  line-height: 20px;
                  font-size: 14px;
                }
                &:before {
                  flex-shrink: 0;
                  display: block;
                  content: "";
                  width: 6px;
                  height: 6px;
                  margin-top: 9px;
                  margin-right: 9px;
                  background: $primary_blue;
                  @media (max-width: $mob-screen) {
                    margin-top: 7px;
                    margin-right: 6px;
                  }
                }
              }
              span {
                display: block;
                margin-top: 10px;
                line-height: 26px;
                font-size: 16px;
                color: #888;
                width: max-content;
                padding-right: 13px;
                background: url(../assets/images/home_more.png) no-repeat right
                  center;
                background-size: 8px auto;
                @media (max-width: $mob-screen) {
                  margin-top: 8px;
                  font-size: 12px;
                }
              }
            }
          }
          &.block2 {
            padding: 16px;
            padding-bottom: 27px;
            @media (max-width: $mob-screen) {
              padding: 16px;
            }
            .link {
              @include flex-start_start;
              flex-wrap: wrap;
              @media (max-width: 810px) {
                display: block;
              }
              a {
                display: block;
                width: calc(50% - 8px);
                background: #f5f6f9;
                border-radius: 5px;
                padding: 16px 0;
                padding-left: 52px;
                line-height: 28px;
                font-size: 20px;
                font-weight: 500;
                color: $primary_blue;
                background-repeat: no-repeat;
                background-position: 16px center;
                background-size: 28px auto;
                margin-left: 15px;
                margin-bottom: 15px;
                @media (max-width: 880px) {
                  width: calc(50% - 5px);
                  margin-left: 10px;
                }
                @media (max-width: 810px) {
                  width: 100%;
                  margin-left: 0;
                  padding-left: 44px;
                  background-position: 12px center;
                }
                @media (max-width: $mob-screen) {
                  padding: 8px 0;
                  padding-left: 34px;
                  background-size: 16px auto;
                  margin-bottom: 8px;
                  line-height: 20px;
                  font-size: 14px;
                }
                &:nth-child(2n + 1) {
                  margin-left: 0;
                }
                &:nth-child(1) {
                  background-image: url(../assets/images/home_contact_1.png);
                }
                &:nth-child(2) {
                  background-image: url(../assets/images/home_contact_2.png);
                }
                &:nth-child(3) {
                  background-image: url(../assets/images/home_contact_3.png);
                }
              }
            }
            .tel {
              display: block;
              margin-top: 13px;
              padding-top: 60px;
              text-align: center;
              line-height: 28px;
              font-size: 20px;
              font-weight: 500;
              color: $primary_red;
              background: url(../assets/images/home_tel.png) no-repeat center
                top;
              background-size: 64px auto;
              @media (max-width: $mob-screen) {
                padding-top: 40px;
                background-size: 40px auto;
                line-height: 18px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@for $i from 2 to 6 {
  .page_theme_#{$i} {
    .Home {
      .top {
        background: nth($bg_color, $i);
        .top_div {
          .big_news {
            a {
              color: nth($text_color, $i);
            }
          }
          .news {
            .right {
              background: nth($bg_color, $i);
              .right_div {
                a {
                  color: nth($text_color, $i);
                  &:before {
                    background: nth($text_color, $i);
                  }
                }
              }
            }
          }
        }
      }
      .content {
        .line {
          .left,
          .right {
            .block {
              background: nth($bg_color, $i);
            }
          }
          .left {
            .nav {
              .nav_item {
                &.current {
                  color: nth($text_color, $i);
                  &:after {
                    background: nth($text_color, $i);
                  }
                }
              }
            }
            .block {
              .news_list {
                a {
                  color: nth($text_color, $i);
                }
              }
              .more {
                a {
                  color: nth($text_color, $i);
                }
              }
              &.news1 {
                .news_list {
                  a {
                    &:before {
                      background: nth($text_color, $i);
                    }
                  }
                }
              }
              &.news2 {
                .news_list {
                  a {
                    span {
                      color: nth($text_color, $i);
                    }
                  }
                }
              }
            }
          }
          .right {
            .block {
              &.block1 {
                a {
                  border: 1px solid nth($text_color, $i);
                  div {
                    color: nth($text_color, $i);
                    &:before {
                      background: nth($text_color, $i);
                    }
                  }
                  span {
                    color: nth($text_color, $i);
                  }
                }
              }
              &.block2 {
                .link {
                  a {
                    border: 1px solid nth($text_color, $i);
                    color: nth($text_color, $i);
                  }
                }
                .tel {
                  color: nth($text_color, $i);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
